import { Injectable } from '@angular/core';
import { Server } from '../server/server.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  /* Método construtor */
  constructor(private server: Server, private storage: Storage) {
    this.storage.get('oauthToken').then((token) => {
      if (token) {
        this.loggedIn.next(true);
      }
    });
  }

  /* Indica se existe um usuário logado */
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  /* pega as informações do usuário logado */
  async getUserInfo() {
    return await this.storage.get('user');
  }

  /* Faz o login do usuário no servidor e guarda a sessão */
  async doLogin(user: User) {
    const result = await this.server
      .post({
        url: 'login',
        body: user,
      })
      .toPromise();

    this.loggedIn.next(true);

    return result;
  }

  /* Faz o logout da aplicação */
  async logOut() {
    this.loggedIn.next(false);
    await this.storage.remove('user');
    return await this.storage.remove('oauthToken');
  }
}

// End file
