import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FormularioGuard } from 'src/services/formulario/formulario.guard';
import { SolicitacaoFotografiaGuard } from 'src/services/fotografo/solicitacao-fotografia.guard';
import { LocadorGuard } from 'src/services/locadores/locador.guard';
import { CanActivateInLogin, CanActivateInLogout } from './app.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule',
    canActivate: [CanActivateInLogout],
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
  },
  {
    path: 'redefinicao-senha/:token',
    loadChildren:
      './redefinicao-senha/redefinicao-senha.module#RedefinicaoSenhaPageModule',
    canActivate: [CanActivateInLogout],
  },
  {
    path: 'documentos/lista',
    loadChildren:
      './documentos/documentos-lista/documentos-lista.module#DocumentosListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'documentos/form',
    loadChildren:
      './documentos/documentos-form/documentos-form.module#DocumentosFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'documentos/form/:id',
    loadChildren:
      './documentos/documentos-form/documentos-form.module#DocumentosFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'documentos/confirmar-documentos/:token',
    loadChildren:
      './documentos/confirmar-documentos/confirmar-documentos.module#ConfirmarDocumentosPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'documentos/ficha-locatario/:token/:idPessoa',
    loadChildren:
      './documentos/ficha-locatario/ficha-locatario.module#FichaLocatarioPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'preencher-documentos/:token',
    loadChildren:
      './preencher-documentos/inicial/inicial.module#InicialPageModule',
    canActivate: [FormularioGuard],
  },
  {
    path: 'preencher-documentos/dados-pessoais/:token',
    loadChildren:
      './preencher-documentos/dados-pessoais/dados-pessoais.module#DadosPessoaisPageModule',
    canActivate: [FormularioGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'preencher-documentos/dados-pessoais/:token/:pessoa',
    loadChildren:
      './preencher-documentos/dados-pessoais/dados-pessoais.module#DadosPessoaisPageModule',
    canActivate: [FormularioGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'preencher-documentos/concluido/:token',
    loadChildren:
      './preencher-documentos/concluido/concluido.module#ConcluidoPageModule',
    canActivate: [FormularioGuard],
  },
  {
    path: 'contratos',
    loadChildren: './contratos/contratos.module#ContratosPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'contratos/:idProcesso',
    loadChildren: './contratos/contratos.module#ContratosPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'contratos/:idProcesso/:token',
    loadChildren: './contratos/contratos.module#ContratosPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'contratos-grid',
    loadChildren:
      './contratos-grid/contratos-grid.module#ContratosGridPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'consultores/lista',
    loadChildren:
      './consultores/consultores-list/consultores-list.module#ConsultoresListPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'consultores/form',
    loadChildren:
      './consultores/consultores-form/consultores-form.module#ConsultoresFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'consultores/form/:id',
    loadChildren:
      './consultores/consultores-form/consultores-form.module#ConsultoresFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'visita/agendar',
    loadChildren:
      './visita-cliente/visita-form/visita-form.module#VisitaFormPageModule',
  },
  {
    path: 'visita/data',
    loadChildren:
      './visita-cliente/visita-data/visita-data.module#VisitaDataPageModule',
  },
  {
    path: 'visita/codigo',
    loadChildren:
      './visita-cliente/visita-codigo/visita-codigo.module#VisitaCodigoPageModule',
  },
  {
    path: 'visita/confirmar',
    loadChildren:
      './visita-cliente/visita-confirmar/visita-confirmar.module#VisitaConfirmarPageModule',
  },
  {
    path: 'visita/agendada',
    loadChildren:
      './visita-cliente/visita-agendada/visita-agendada.module#VisitaAgendadaPageModule',
  },
  {
    path: 'visita/reagendar/:id/:token',
    loadChildren:
      './visita-cliente/visita-reagendar/visita-reagendar.module#VisitaReagendarPageModule',
  },
  {
    path: 'visita/lista',
    loadChildren:
      './visita-funcionario/visita-lista/visita-lista.module#VisitaListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'visita/agendamento',
    loadChildren:
      './visita-funcionario/visita-agendamento/visita-agendamento.module#VisitaAgendamentoPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'visita/agendamento/:id',
    loadChildren:
      './visita-funcionario/visita-agendamento/visita-agendamento.module#VisitaAgendamentoPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'visita/cancelamento/:id/:token',
    loadChildren:
      './visita-cliente/visita-cancelamento/visita-cancelamento.module#VisitaCancelamentoPageModule',
  },
  {
    path: 'visita/cancelada/:id/:token',
    loadChildren:
      './visita-cliente/visita-cancelada/visita-cancelada.module#VisitaCanceladaPageModule',
  },
  {
    path: 'imoveis/lista',
    loadChildren:
      './imoveis/imovel-lista/imovel-lista.module#ImovelListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'imoveis/form',
    loadChildren:
      './imoveis/imovel-form/imovel-form.module#ImovelFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'imoveis/form/:id',
    loadChildren:
      './imoveis/imovel-form/imovel-form.module#ImovelFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'imoveis/form/locador/:locadorId',
    loadChildren:
      './imoveis/imovel-form/imovel-form.module#ImovelFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'configura/horario',
    loadChildren:
      './visita-funcionario/configura-horario/configura-horario.module#ConfiguraHorarioPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'perfil-acesso/lista',
    loadChildren:
      './perfil-acesso/perfil-acesso-lista/perfil-acesso-lista.module#PerfilAcessoListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'perfil-acesso/form/:id',
    loadChildren:
      './perfil-acesso/perfil-acesso-form/perfil-acesso-form.module#PerfilAcessoFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'perfil-acesso/form',
    loadChildren:
      './perfil-acesso/perfil-acesso-form/perfil-acesso-form.module#PerfilAcessoFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'negociacao/lista',
    loadChildren:
      './negociacao/negociacao-lista/negociacao-lista.module#NegociacaoListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'negociacao/form',
    loadChildren:
      './negociacao/negociacao-form/negociacao-form.module#NegociacaoFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'negociacao/form/:id',
    loadChildren:
      './negociacao/negociacao-form/negociacao-form.module#NegociacaoFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'negociacao/propostas/:id',
    loadChildren:
      './negociacao/negociacao-propostas/negociacao-propostas.module#NegociacaoPropostasPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'negociacao/contraproposta/:tipo/:id/:proposta',
    loadChildren:
      './negociacao/negociacao-contraproposta/negociacao-contraproposta.module#NegociacaoContrapropostaPageModule',
  },
  {
    path: 'cliente/contraproposta/:id',
    loadChildren:
      './negociacao/cliente-contraproposta/cliente-contraproposta.module#ClienteContrapropostaPageModule',
  },
  {
    path: 'cliente/proposta-aceita/:id/:proposta',
    loadChildren:
      './negociacao/cliente-proposta-aceita/cliente-proposta-aceita.module#ClientePropostaAceitaPageModule',
  },
  {
    path: 'locador/proposta-aceita/:id/:proposta',
    loadChildren:
      './negociacao/locador-proposta-aceita/locador-proposta-aceita.module#LocadorPropostaAceitaPageModule',
  },
  {
    path: 'locador/proposta-rejeitada/:id/:proposta',
    loadChildren:
      './negociacao/locador-proposta-rejeitada/locador-proposta-rejeitada.module#LocadorPropostaRejeitadaPageModule',
  },
  {
    path: 'cliente/proposta-enviada',
    loadChildren:
      './negociacao/cliente-proposta-enviada/cliente-proposta-enviada.module#ClientePropostaEnviadaPageModule',
  },
  {
    path: 'inicial-fiador/:token',
    loadChildren:
      './preencher-documentos/inicial-fiador/inicial-fiador.module#InicialFiadorPageModule',
    canActivate: [FormularioGuard],
  },
  {
    path: 'fiador/:token',
    loadChildren:
      './preencher-documentos/fiador/fiador.module#FiadorPageModule',
    canActivate: [FormularioGuard],
  },
  {
    path: 'fiador/:token/:pessoa',
    loadChildren:
      './preencher-documentos/fiador/fiador.module#FiadorPageModule',
    canActivate: [FormularioGuard],
  },
  {
    path: 'imoveis/configuracao',
    loadChildren:
      './imoveis/configuracao-documentacao/configuracao-documentacao.module#ConfiguracaoDocumentacaoPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'locadores/lista',
    loadChildren:
      './locadores/locadores-lista/locadores-lista.module#LocadoresListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'locadores/form',
    loadChildren:
      './locadores/locadores-form/locadores-form.module#LocadoresFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'locadores/configurar-documentos',
    loadChildren:
      './locadores/configurar-documentos/configurar-documentos.module#ConfigurarDocumentosPageModule',
    canActivate: [CanActivateInLogin],
  },
  // {
  //     path: 'locador-imovel/:id/:token',
  //     loadChildren: './locador/locador-formulario/locador.module#LocadorPageModule',
  //     canActivate: [LocadorGuard]
  // },
  {
    path: 'imovel/cadastro/:id/:imovelId',
    loadChildren:
      './locador/locador-formulario/locador.module#LocadorPageModule',
    // canActivate: [LocadorGuard]
  },
  {
    path: 'locador-form/:id/:locadorId',
    loadChildren:
      './locador/locador-formulario/locador.module#LocadorPageModule',
  },
  {
    path: 'locador-documentacao/:id/:imovelId',
    loadChildren:
      './locador/locador-documentacao/locador-documentacao.module#LocadorDocumentacaoPageModule',
  },
  {
    path: 'conferir-locador/:id',
    loadChildren:
      './locadores/conferir-locador/conferir-locador.module#ConferirLocadorPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'fotografo/formulario',
    loadChildren:
      './fotografo/fotografo-form/fotografo-form.module#FotografoFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'fotografo/formulario/:id',
    loadChildren:
      './fotografo/fotografo-form/fotografo-form.module#FotografoFormPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'fotografo/lista',
    loadChildren:
      './fotografo/fotografo-lista/fotografo-lista.module#FotografoListaPageModule',
    canActivate: [CanActivateInLogin],
  },
  {
    path: 'aceitar-solicitacao-fotografia/:id',
    loadChildren:
      './fotografo/aceite-proposta/aceite-proposta.module#AceitePropostaPageModule',
    canActivate: [SolicitacaoFotografiaGuard],
  },
  {
    path: 'envio-fotos/:token',
    loadChildren:
      './fotografo/envio-fotos/envio-fotos.module#EnvioFotosPageModule',
  },
  {
    path: 'contrato-administracao/lista',
    // tslint:disable-next-line: max-line-length
    loadChildren:
      './contrato-administracao/contrato-administracao-lista/contrato-administracao-lista.module#ContratoAdministracaoListaPageModule',
  },
  {
    path: 'contrato-administracao/form/:id',
    // tslint:disable-next-line: max-line-length
    loadChildren:
      './contrato-administracao/contrato-administracao-form/contrato-administracao-form.module#ContratoAdministracaoFormPageModule',
  },
  {
    path: 'contrato-administracao/form/:id/:imovelId',
    // tslint:disable-next-line: max-line-length
    loadChildren:
      './contrato-administracao/contrato-administracao-form/contrato-administracao-form.module#ContratoAdministracaoFormPageModule',
  },
  {
    path: 'clausulas',
    loadChildren:
      './contrato-administracao/clausulas/clausulas.module#ClausulasPageModule',
  },
  {
    path: 'locadores-form',
    loadChildren:
      './locadores/locadores-form/locadores-form.module#LocadoresFormPageModule',
  },
  {
    path: 'imovel-info/:id',
    loadChildren:
      './imoveis/imovel-info/imovel-info.module#ImovelInfoPageModule',
  },
  {
    path: 'base-dados',
    loadChildren: './base-dados/base-dados.module#BaseDadosPageModule',
  },
  {
    path: 'base-dados/imovel/form',
    loadChildren:
      './base-dados/imoveis-form/imoveis-form.module#ImoveisFormPageModule',
  },
  {
    path: 'base-dados/imovel/:id',
    loadChildren:
      './base-dados/imoveis-info/imoveis-info.module#ImoveisInfoPageModule',
  },
  {
    path: 'visitas-kanban',
    loadChildren:
      './visitas-kanban/visitas-kanban.module#VisitasKanbanPageModule',
  },
  {
    path: 'documentacao-kanban',
    loadChildren:
      './documentos-kanban/documentos-kanban.module#DocumentosKanbanPageModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// End of file
