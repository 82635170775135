import { SERVER_API } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Server {
  /* Headers da requisição */
  headers = {};

  /* Método construtor */
  constructor(public http: HttpClient, private storage: Storage) {}

  /* Obtém o endpoint da API */
  getEndpoint(action: string): string {
    return `${SERVER_API}/${action}`;
  }

  /* Adiciona o token de autenticação */
  async withAuthHeaders({ withContentType = true } = {}) {
    const token = await this.storage.get('oauthToken');
    this.headers = {
      Authorization: `Bearer ${token}`,
    };
    if (withContentType) {
      this.headers['content-type'] = 'application/json';
    }
    return this;
  }

  /* Seta o content type */
  withContentType(contentType) {
    if (!contentType) {
      this.headers['Content-Type'] = contentType;
    } else {
      this.headers = {};
      this.headers['Content-Type'] = 'application/json';
    }

    return this;
  }

  /* Executa um método POST */
  post({ url, body }): Observable<any> {
    const headers = JSON.parse(JSON.stringify(this.headers));
    return this.http.post(this.getEndpoint(url), body, { headers });
  }

  /* Executa um método GET */
  get({ url }): Observable<any> {
    const headers = JSON.parse(JSON.stringify(this.headers));
    return this.http.get(this.getEndpoint(url), { headers });
  }

  /* Executa um método PUT */
  put({ url, body }): Observable<any> {
    const headers = JSON.parse(JSON.stringify(this.headers));
    return this.http.put(this.getEndpoint(url), body, { headers });
  }

  /* Executa um método PATCH */
  patch({ url, body }): Observable<any> {
    const headers = JSON.parse(JSON.stringify(this.headers));
    return this.http.patch(this.getEndpoint(url), body, { headers });
  }

  /* Executa um método DELETE */
  delete({ url }): Observable<any> {
    const headers = JSON.parse(JSON.stringify(this.headers));
    return this.http.delete(this.getEndpoint(url), { headers });
  }

  uploadImage({ url, body }): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.getEndpoint(url), body, { headers });
  }
}

// End of file
