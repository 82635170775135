import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  isActived = false;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.isActived = true;
    }, 0);
  }
}
