import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { PRODUCTION } from './environments/environment';

if (PRODUCTION) {
  enableProdMode();

  // Desabilita todos os comandos console.log
  console.log = () => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
