import { ModalController } from '@ionic/angular';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Selectable } from './select.model';
import { SelectModalComponent } from '../select-modal/select-modal.component';

@Component({
  selector: 'h-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Output()
  hasChange: EventEmitter<Selectable> = new EventEmitter();

  @Input()
  dataSet: Selectable[] = [
    {
      value: 1,
      label: 'Brasil',
    },
    {
      value: 2,
      label: 'Argentina',
    },
    {
      value: 3,
      label: 'Uruguai',
    },
  ];

  @Input()
  label = 'Selecione um item';

  @Input()
  selected: Selectable = {};

  /**
   * Indica se a lista esta aberta ou nao
   *
   */
  isOpen = false;

  /**
   * Método construtor
   *
   */
  constructor(public modalCtrl: ModalController) {}

  /**
   * Faz o toggle da lista
   *
   */
  async abrirModal() {
    const modal = await this.modalCtrl.create({
      component: SelectModalComponent,
      componentProps: {
        label: this.label,
        dataSet: this.dataSet,
        selected: this.selected,
      },
    });

    modal.present();
    const { data } = await modal.onWillDismiss();
    const { value } = data;
    if (value) {
      this.selected = value;
    } else {
      this.selected = null;
    }
    this.hasChange.emit(this.selected);
  }
}

// End of file
