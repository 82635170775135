import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ImobiliariaService } from 'src/services/imobiliaria/imobiliaria.service';
import { AuthService } from './../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CanActivateInLogin implements CanActivate {
  /* Método construtor */
  constructor(private auth: AuthService, private router: Router) {}

  /* Verifica se pode entrar na rota */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class CanActivateInLogout implements CanActivate {
  /* Método construtor */
  constructor(
    private auth: AuthService,
    private router: Router,
    private imobiliariaService: ImobiliariaService
  ) {}

  /* Verifica se pode entrar na rota */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.router.navigate(['/home']);
          return false;
        }
        return true;
      })
    );
  }
}

// End of file
