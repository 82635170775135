import * as moment from 'moment';

export class Notificacao {
  id: number;
  pessoa: string;
  token: string;
  data: string;
  horario: string;
  tipo: string;
  criacao: any;

  /* Método construtor */
  constructor(
    id: number,
    pessoa: string,
    token: string,
    data: string,
    horario: string,
    tipo: string
  ) {
    this.id = id;
    this.pessoa = pessoa;
    this.token = token;
    this.data = data;
    this.horario = horario;
    this.tipo = tipo;
    this.criacao = moment().format('LTS');
  }
}

// End of file
