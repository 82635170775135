import {
  HttpEvent,
  HttpResponse,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

interface ServerError {
  status: number;
  ok: boolean;
  error: string;
  message: string;
  details: string;
}

export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Intercepta a requisicao
   *
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // Faz o tratamento do sucesso
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: event.body });
        }
        return event;
      }),
      // Faz o tratamento do erro
      catchError((error: HttpErrorResponse) => {
        let errorMessage = error.statusText;
        let detalhes;
        if (error.error instanceof ErrorEvent) {
          errorMessage = error.error.error;
        } else if (error.error.errors) {
          return throwError(Object.values(error.error.errors));
        } else {
          const serverError: ServerError = error.error;
          errorMessage = serverError.error;
          detalhes = !!serverError.details ? serverError.details : '';
        }
        return throwError(
          `${errorMessage}
                     ${detalhes}`
        );
      })
    );
  }
}

// End of file
