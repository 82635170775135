import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { FotografoService } from './fotografo.service';

@Injectable({
  providedIn: 'root',
})
export class SolicitacaoFotografiaGuard implements CanActivate {
  /* Método Construtor */
  constructor(private fotografoService: FotografoService) {}

  /* Solicita a API os dados do formulário */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // ID da solicitação
    const id = next.params.id / 64;

    try {
      const retorno = await this.fotografoService.verificarSolicitacaoFotografo(
        id
      );

      return retorno.existe ? true : false;
    } catch (error) {
      console.error('ID inválido', error);

      return false;
    }
  }
}

// End of file
