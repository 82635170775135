import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alerta-concluido',
  templateUrl: './alerta-concluido.component.html',
  styleUrls: ['./alerta-concluido.component.scss'],
})
export class AlertaConcluidoComponent {
  @Input() public titulo = 'Parabéns';

  @Input() public concluidoText = 'Ok, obrigado';

  @Input() public content = [];

  /**
   * Método construtor
   *
   */
  constructor(private modalCtrl: ModalController) {}

  /**
   * Fecha a página do modal
   *
   */
  public dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
