import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alerta-sucesso',
  templateUrl: './alerta-sucesso.component.html',
  styleUrls: ['./alerta-sucesso.component.scss'],
})
export class AlertaSucessoComponent {
  @Input() public titulo = 'Parabéns. Algo deu certo.';

  @Input() public successText = 'Ok, obrigado';

  @Input() public content = [
    'Você fez algo que funcionou.',
    '<b>Parabéns</b> e boa sorte',
  ];

  /**
   * Método construtor
   *
   */
  constructor(public modalCtrl: ModalController) {}

  /**
   * Fecha a página do modal
   *
   */
  public dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}

// End of file
