import { Injectable } from '@angular/core';
import { Documentacao } from 'src/app/documentos/confirmar-documentos/documentacao';
import { Server } from '../server/server.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentoService {
  /* Método construtor */
  constructor(private server: Server) {}

  /* Faz um upload de documento de renda */
  async enviarDocumento(formData: FormData, pessoaId: number) {
    return (
      await this.server
        .withContentType(false)
        .post({
          url: `anexar-documento/${pessoaId}`,
          body: formData,
        })
        .toPromise()
    ).success;
  }

  /* Lista os documentos de renda enviados pelo locatário ou fiador */
  async listarDocumentos(pessoaId: number) {
    return await this.server
      .withContentType(true)
      .get({
        url: `listar-documentos/${pessoaId}`,
      })
      .toPromise();
  }

  /* Lista os documentos de renda a serem enviados pelo locatário ou fiador */
  async obterDocumentosRenda(renda: number) {
    return await this.server
      .get({
        url: `listar-documentos-renda/${renda}`,
      })
      .toPromise();
  }

  /* Remove um documento de renda enviado */
  async removerDocumentoEnviado(documentoId: number) {
    return (
      await this.server
        .withContentType(true)
        .delete({
          url: `excluir-documento-enviado/${documentoId}`,
        })
        .toPromise()
    ).success;
  }

  /* Obtém os documentos de garantia a serem enviados pelo locatário */
  async obterDocumentosGarantia(garantiaId: number) {
    return await this.server
      .withContentType(true)
      .get({
        url: `listar-documentos-garantia/${garantiaId}`,
      })
      .toPromise();
  }

  /* Obtém os documentos de garantia a serem enviados pelo fiador */
  async obterDocumentosGarantiaFiador(garantiaId: number) {
    return await this.server
      .withContentType(true)
      .get({
        url: `listar-documentos-garantia-fiador/${garantiaId}`,
      })
      .toPromise();
  }

  /* Faz um upload de documento de garantia */
  async enviarDocumentoGarantia(formData: FormData, pessoaId: number) {
    return (
      await this.server
        .withContentType(false)
        .post({
          url: `anexar-documento-garantia/${pessoaId}`,
          body: formData,
        })
        .toPromise()
    ).success;
  }

  /* Lista os documentos de garantia enviados por uma pessoa */
  async listarDocumentosGarantia(pessoaId: number) {
    return await this.server
      .withContentType(true)
      .get({
        url: `documento-garantia/${pessoaId}`,
      })
      .toPromise();
  }

  /* Remove um documento de garantia enviado */
  async removerDocumentoEnviadoGarantia(documentoId: number) {
    return (
      await this.server
        .withContentType(true)
        .delete({
          url: `documento-garantia/${documentoId}`,
        })
        .toPromise()
    ).success;
  }

  /* Aprovar um documento de renda enviado */
  async aprovarDocumentoRenda(documentoId: number) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `aprovar-documento/${documentoId}`,
        body: {},
      })
      .toPromise();
  }

  /* Reprovar um documento de renda enviado */
  async reprovarDocumentoRenda(documentoId: number, motivo: string) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `reprovar-documento/${documentoId}`,
        body: {
          documento: documentoId,
          observacao: motivo,
        },
      })
      .toPromise();
  }

  /* Aprovar um documento de garantia enviado */
  async aprovarDocumentoGarantia(documentoId: number) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `aprovar-documento-garantia/${documentoId}`,
        body: {},
      })
      .toPromise();
  }

  /* Reprovar um documento de garantia enviado */
  async reprovarDocumentoGarantia(documentoId: number, motivo: string) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `reprovar-documento-garantia/${documentoId}`,
        body: { observacao: motivo },
      })
      .toPromise();
  }

  /* Listar os documentos de renda enviados por tipo de documento */
  async listarDocumentosRendaEnviadosPorTipo(
    pessoaId: number,
    documentoId: number
  ) {
    return await this.server
      .withContentType(true)
      .get({
        url: `documento-renda-enviado/${pessoaId}/${documentoId}`,
      })
      .toPromise();
  }

  /* Listar os documentos de garantia enviados por tipo de documento */
  async listarDocumentosGarantiaEnviadosPorTipo(
    pessoaId: number,
    documentoId: number
  ) {
    return await this.server
      .withContentType(true)
      .get({
        url: `documento-garantia-enviado/${pessoaId}/${documentoId}`,
      })
      .toPromise();
  }

  /* Listar toda a documentação para conferência */
  async listarDocumentacao(formularioId: number) {
    const documentacao: Documentacao = await (
      await this.server.withAuthHeaders({ withContentType: true })
    )
      .get({
        url: `documentacao/${formularioId}`,
      })
      .toPromise();

    return documentacao;
  }

  /* Aprovar na conferência um documento de locador */
  async aprovarDocumentoLocador(documentoId: number) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `aprovar-documento-locador/${documentoId}`,
        body: {},
      })
      .toPromise();
  }

  /* Reprovar na conferência um documento de locador */
  async reprovarDocumentoLocador(documentoId: number, motivo: string) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `reprovar-documento-locador/${documentoId}`,
        body: { observacao: motivo },
      })
      .toPromise();
  }

  /* Aprovar na conferência um documento de imóvel */
  async aprovarDocumentoImovel(documentoId: number) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `aprovar-documento-imovel/${documentoId}`,
        body: {},
      })
      .toPromise();
  }

  /* Reprovar na conferência um documento de imóvel */
  async reprovarDocumentoImovel(documentoId: number, motivo: string) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: `reprovar-documento-imovel/${documentoId}`,
        body: { observacao: motivo },
      })
      .toPromise();
  }
}

// End of file
