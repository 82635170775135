import { Injectable } from '@angular/core';
import { Server } from '../server/server.service';

@Injectable({
  providedIn: 'root',
})
export class ImobiliariaService {
  constructor(private server: Server) {}

  async obterDadosFuncionario() {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .get({ url: 'imobiliaria-usuario' })
      .toPromise();
  }
}

// End of file
