import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  @Input() photos: Array<Photo> = new Array<Photo>();
  @Input() height: string = '0px';
  @Output() changeList = new EventEmitter<any>();
  @Output() newImage = new EventEmitter<any>();
  @Output() deletedImage = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.orderGalery();
  }

  orderGalery(): void {
    this.photos = this.photos.sort((a, b) => a.order - b.order);
  }

  sendChanges() {
    this.orderPhotos();
    this.changeList.emit(this.photos);
  }

  orderPhotos() {
    this.photos.map((photo, index) => {
      photo.order = index;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.photos, event.previousIndex, event.currentIndex);
    this.sendChanges();
  }

  handleDeleteImage(photo: Photo): void {
    this.photos.splice(photo.order, 1);
    this.deletedImage.emit(photo);
    this.sendChanges();
  }

  handleMarkShowOnSite(photo: Photo, event: any): void {
    this.photos.find((x) => x.id == photo.id).showOnSite = event.checked;
    this.sendChanges();
  }

  handleSendNewPhoto(event) {
    const selecionaArquivo = event;
    const file: File = selecionaArquivo.files[0];

    if (file) this.newImage.emit(file);

    var galleryContainer = document.getElementById('image-container');
    galleryContainer.scrollLeft = 10000;
  }
}

type Photo = {
  id?: number;
  nome: string;
  order: number;
  showOnSite: boolean;
  src: File;
};
