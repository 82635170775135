import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alerta-erro',
  templateUrl: './alerta-erro.component.html',
  styleUrls: ['./alerta-erro.component.scss'],
})
export class AlertaErroComponent {
  @Input() public titulo = 'Ops! Algo deu errado.';

  @Input() public errorText = 'Ok, obrigado';

  @Input() public content = [];

  /**
   * Método construtor
   *
   */
  constructor(public modalCtrl: ModalController) {}

  /**
   * Fecha a página do modal
   *
   */
  public dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}

// End of file
