import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCardModule,
  MatListModule,
  MatSnackBarModule,
} from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ComponentsModule } from 'src/components/components.module';
import { FormularioGuard } from 'src/services/formulario/formulario.guard';
import { HttpErrorInterceptor } from './../services/server/http-error.interceptor';
import { AlertasModule } from './alertas/alertas.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ManipuladorErro } from './manipulador-erro';
import { NotificacaoComponent } from './notificacao/notificacao.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [AppComponent, AutofocusDirective, NotificacaoComponent],
  entryComponents: [NotificacaoComponent],
  exports: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    RouterModule,
    AlertasModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MatTooltipModule,
    HttpClientModule,
    MatTableModule,
    MatDividerModule,
    MatChipsModule,
    MatSortModule,
    NgxMaskIonicModule.forRoot(),
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MdePopoverModule,
    MatSnackBarModule,
    IonicStorageModule.forRoot({
      name: '__snelloDB',
      driverOrder: ['localstorage', 'websql'],
    }),
    ComponentsModule,
    MatCarouselModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FormularioGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: ManipuladorErro,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// End of file
