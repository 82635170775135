import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageViewerModule } from 'ngx-image-viewer';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { DialogoConferirDocsComponent } from 'src/components/dialogo-conferir-docs/dialogo-conferir-docs';
import { DialogoDocComponent } from './dialogo-doc/dialogo-doc';
import { LoadingComponent } from './loading/loading.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SelectModalComponent } from './select-modal/select-modal.component';
import { SelectComponent } from './select/select.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    SelectComponent,
    SelectModalComponent,
    LoadingComponent,
    DialogoConferirDocsComponent,
    DialogoDocComponent,
    GalleryComponent,
  ],
  entryComponents: [
    SelectComponent,
    SelectModalComponent,
    LoadingComponent,
    DialogoConferirDocsComponent,
    DialogoDocComponent,
    GalleryComponent,
  ],
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PinchZoomModule,
    PdfViewerModule,
    DragDropModule,
    ImageViewerModule.forRoot({
      btnClass: 'default',
      zoomFactor: 0.1,
      containerBackgroundColor: '#ccc',
      wheelZoom: true,
      allowFullscreen: false,
      allowKeyboardNavigation: true,
      btnIcons: {
        zoomIn: 'fa fa-plus',
        zoomOut: 'fa fa-minus',
        rotateClockwise: 'fa fa-repeat',
        rotateCounterClockwise: 'fa fa-undo',
      },
      btnShow: {
        zoomIn: true,
        zoomOut: true,
        rotateClockwise: true,
        rotateCounterClockwise: true,
      },
    }),
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
  exports: [
    SelectComponent,
    LoadingComponent,
    SelectModalComponent,
    DialogoConferirDocsComponent,
    DialogoDocComponent,
    GalleryComponent,
  ],
})
export class ComponentsModule {}

// End of file
