import { Injectable } from '@angular/core';
import { Server } from '../server/server.service';

@Injectable({
  providedIn: 'root',
})
export class FotografoService {
  constructor(private server: Server) {}

  /* Anexa uma foto enviada pelo fotógrafo */
  async anexarFoto(formData: FormData) {
    return await this.server
      .uploadImage({
        url: 'foto-fotografo',
        body: formData,
      })
      .toPromise();
  }

  /* Cadastra um fotografo */
  async cadastrarFotografo(fotografo) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .post({
        url: 'adicionar-fotografo',
        body: { ...fotografo },
      })
      .toPromise();
  }

  /* Lista todos fotografos */
  async obterFotografos() {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .get({
        url: 'listar-fotografos',
      })
      .toPromise();
  }

  /* Lista um fotografo */
  async obterFotografo(fotografoId: number) {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .get({
        url: `listar-fotografo/${fotografoId}`,
      })
      .toPromise();
  }

  /* Excluir um fotografo */
  async excluirFotografo(fotografoId: number) {
    return await (await this.server.withAuthHeaders({ withContentType: false }))
      .delete({ url: `deletar-fotografo/${fotografoId}` })
      .toPromise();
  }

  /* Atualizar um fotografo */
  async atualizarFotografo(fotografoId: number, fotografo) {
    return await (
      await this.server.withAuthHeaders()
    )
      .put({
        url: `atualizar-fotografo/${fotografoId}`,
        body: { ...fotografo },
      })
      .toPromise();
  }

  /* Alterar o status de um fotografo */
  async alterarStatus(fotografoId: number, status: boolean) {
    return await (
      await this.server.withAuthHeaders()
    )
      .put({
        url: `alterar-status-fotografo/${fotografoId}`,
        body: { status },
      })
      .toPromise();
  }

  /* Listar apenas fotógrafos disponíveis */
  async obterFotografosDisponiveis() {
    return (await this.server.withAuthHeaders({ withContentType: true }))
      .get({
        url: 'fotografos-disponiveis',
      })
      .toPromise();
  }

  async aprovarImagensImovel(imovelId) {
    return await this.server
      .withContentType(true)
      .put({
        url: `aprovar-fotos/${imovelId}`,
        body: {},
      })
      .toPromise();
  }

  /* Solicitar um fotógrafo para fotografar um imóvel de locador */
  async solicitarFotografos(formBody) {
    return await (
      await this.server.withAuthHeaders({ withContentType: true })
    )
      .post({
        url: `fotografo/solicitar`,
        body: formBody,
      })
      .toPromise();
  }

  /* Verificar se existe uma solicitação de fotografia */
  async verificarSolicitacaoFotografo(solicitacaoId: number) {
    return await this.server
      .withContentType(true)
      .get({
        url: `agenda-fotografo/${solicitacaoId}`,
      })
      .toPromise();
  }

  /* Aceitar uma solicitação para fotografar imóvel */
  async aceitarSolicitacao(solicitacaoId: number) {
    return await this.server
      .withContentType(true)
      .put({
        url: `fotografo/aceitar/${solicitacaoId}`,
        body: {},
      })
      .toPromise();
  }

  /* Verificar se o link para envio das fotos ainda é válido */
  async verificarLink(token: string) {
    return await this.server
      .withContentType(true)
      .post({
        url: `envio-foto/verificar`,
        body: { token },
      })
      .toPromise();
  }

  /* Finaliza o envio de fotos enviadas pelo fotógrafo */
  async finalizaEnvioFotos(imovelId) {
    return await this.server
      .withContentType(false)
      .post({
        url: 'finaliza-fotos',
        body: { ...imovelId },
      })
      .toPromise();
  }

  /* Remove uma foto enviada pelo fotógrafo */
  async removerFoto(fotoId: number, token: string) {
    return await this.server
      .withContentType(true)
      .delete({
        url: `foto-fotografo/deletar/${fotoId}/${token}`,
      })
      .toPromise();
  }
}

// End of file
