import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Observable, Subject } from 'rxjs';
import { Server } from '../server/server.service';
import { PUSHER_APP_KEY } from './../../environments/environment';
import { Notificacao } from './notificacao';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificacaoService {
  private subject: Subject<Notificacao> = new Subject<Notificacao>();

  private pusherClient: Pusher;

  /* Informações do usuário a partir do local storage */
  userId: number;

  /* Método construtor */
  constructor(private server: Server, private authService: AuthService) {
    this.pusherClient = new Pusher(PUSHER_APP_KEY, {
      cluster: 'us2',
      forceTLS: true,
    });

    // Obtém o ID do usuário
    this.getUserId();
  }

  /* Verifica o ID do usuário e ouve as notificações em seus canais privados */
  async getUserId() {
    const user = await this.authService.getUserInfo();
    if (user !== null) {
      this.userId = user.info.id;
      await this.ouvirAgendamentosVisita(this.userId);
      await this.ouvirDocumentacaoDocumentacao(this.userId);
    }
  }

  /* Inscreve o usuário no canal do Pusher de notificações de agendamento de Visitas */
  async ouvirAgendamentosVisita(userId: number) {
    const channel = this.pusherClient.subscribe(
      `visita-agendada-cliente-${userId}`
    );

    channel.bind(
      'visita-agendada-cliente',
      (data: {
        message: {
          idVisita: number;
          visitante: string;
          token: string;
          data: string;
          horario: string;
          tipo: string;
        };
        userId: number;
      }) => {
        this.subject.next(
          new Notificacao(
            data.message.idVisita,
            data.message.visitante,
            data.message.token,
            data.message.data,
            data.message.horario,
            (data.message.tipo = 'Agendamento')
          )
        );
      }
    );
  }

  /* Inscreve o usuário no canal do Pusher de notificações de envio de documentação */
  async ouvirDocumentacaoDocumentacao(userId: number) {
    const channel = this.pusherClient.subscribe(`documento-enviado-${userId}`);

    channel.bind(
      'documento-enviado',
      (data: {
        message: {
          idDocumento: number;
          pessoa: string;
          token: string;
          data: string;
          horario: string;
          tipo: string;
        };
        userId: number;
      }) => {
        this.subject.next(
          new Notificacao(
            data.message.idDocumento,
            data.message.pessoa,
            data.message.token,
            data.message.data,
            data.message.horario,
            (data.message.tipo = 'Documentação')
          )
        );
      }
    );
  }

  /* Recebe novas notificações no padrão Observer */
  getFeedItems(): Observable<Notificacao> {
    return this.subject.asObservable();
  }

  /* Obtém as notificações pela API */
  async obterNotificacoes() {
    return (await this.server.withAuthHeaders())
      .get({ url: 'notificacao' })
      .toPromise();
  }

  /* Marca uma notificação como lida */
  async marcarNotificacao(notificacao: number) {
    return (await this.server.withAuthHeaders())
      .put({
        url: `notificacao/${notificacao}`,
        body: {},
      })
      .toPromise();
  }

  /* Marca todas as notificações como lidas */
  async marcarTodasNotificacoes() {
    return (await this.server.withAuthHeaders())
      .put({
        url: 'notificacao',
        body: {},
      })
      .toPromise();
  }
}

// End of file
