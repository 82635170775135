import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

import { AlertaSucessoComponent } from './alerta-sucesso/alerta-sucesso.component';
import { AlertaErroComponent } from './alerta-erro/alerta-erro.component';
import { AlertaConcluidoComponent } from './alerta-concluido/alerta-concluido.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [
    AlertaSucessoComponent,
    AlertaErroComponent,
    AlertaConcluidoComponent,
  ],
  entryComponents: [
    AlertaSucessoComponent,
    AlertaErroComponent,
    AlertaConcluidoComponent,
  ],
  exports: [
    AlertaSucessoComponent,
    AlertaErroComponent,
    AlertaConcluidoComponent,
  ],
})
export class AlertasModule {}

// End of file
