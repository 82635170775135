import { ErrorHandler } from '@angular/core';
import bugsnagClient from './bugsnag';
import { PRODUCTION } from 'src/environments/environment';

export class ManipuladorErro implements ErrorHandler {
  handleError(error: any): void {
    if (PRODUCTION) {
      bugsnagClient.notify(new Error(error));
    } else {
      console.error(error);
    }
  }
}

// End of file
