import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoadingController } from '@ionic/angular';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { SERVER_STORAGE } from 'src/environments/environment';
import { AlertService } from 'src/services/alert/alert.service';
import { DocumentoService } from 'src/services/documento/documento.service';

export interface DialogData {
  id: string;
  motivo: string;
}

@Component({
  selector: 'dialogo-doc',
  styleUrls: ['dialogo-doc.scss'],
  templateUrl: 'dialogo-doc.html',
})
export class DialogoDocComponent {
  reprovado = false;
  aprovado = false;

  // Valida motivo
  ngForm = new FormGroup({
    motivo: new FormControl(this.data.motivo, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(800),
    ]),
  });

  // Loading
  loading = false;

  urlBase = SERVER_STORAGE;

  // Carregamento do PDF
  progressData: PDFProgressData;
  isLoaded = false;
  error: any;

  // Zoom do visualizador de PDF
  zoom = 1.0;

  // Rotação do PDF
  rotation = 0;

  imagem = [];

  // Tipo do parâmetro (garantia ou documento)
  tipo;

  // Filtra o nome do tipo do parâmetro
  filtro: string[];

  // Informações do parametro
  infos: any;

  /* Método construtor */
  constructor(
    public dialogRef: MatDialogRef<DialogoDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private documentoService: DocumentoService,
    private loadingCtrl: LoadingController,
    private alertService: AlertService
  ) {
    // Verifica qual o tipo de parametro
    this.verificaTipo(data);
  }

  /* Mostra o conteúdo para reprovar documentação */
  selecionaReprovar() {
    this.reprovado = true;
    this.aprovado = false;
  }

  /* Mostra o conteúdo para aprovar documentação */
  selecionaAprovar() {
    this.reprovado = false;
    this.aprovado = true;
  }

  /* Aprova documentação */
  async aprova() {
    const loading = await this.loadingCtrl.create({ message: 'Aprovando...' });
    loading.present();
    this.loading = true;

    try {
      let retorno: any;
      retorno = await this.documentoService.aprovarDocumentoImovel(
        this.data.id
      );
      this.dialogRef.close(retorno);
    } catch (error) {
      console.error(error);

      this.alertService
        .setTitulo('Ops')
        .addLinha('Houve um erro ao aprovar este documento')
        .addLinha(error)
        .mostrarErro();
    } finally {
      this.loading = false;
      loading.dismiss();
    }
  }

  /* Reprova documentação */
  async reprova() {
    const loading = await this.loadingCtrl.create({ message: 'Reprovando...' });
    loading.present();
    this.loading = true;

    try {
      let retorno: any;
      retorno = await this.documentoService.reprovarDocumentoImovel(
        this.data.id,
        this.data.motivo
      );
      this.dialogRef.close(retorno);
    } catch (error) {
      console.error(error);

      this.alertService
        .setTitulo('Ops')
        .addLinha('Houve um erro ao reprovar este documento')
        .addLinha(error)
        .mostrarErro();
    } finally {
      this.loading = false;
      loading.dismiss();
    }
  }

  /* Em caso de erro na visualização do PDF */
  onError(error: any) {
    console.error(error);
  }

  /* Verifica o tipo de parâmetro */
  verificaTipo(data: { documento?: any; garantia?: any }) {
    // Pega as chaves de data
    const tipo = Object.keys(data);

    // Filtra e pega apenas o nome do parametro
    this.filtro = tipo.filter((elem) => {
      return elem !== 'id' && elem !== 'observacao';
    });

    this.imagem.push(this.urlBase + '/' + data[this.filtro[0]].caminho);

    // filtra pelo tipo de documento, garantia ou renda
    if (this.filtro[0] === 'documento') {
      this.tipo = data.documento;
      this.infos = data.documento.documentos;
    } else {
      this.tipo = data.garantia;
      this.infos = data.garantia.documentos_garantia;
    }
  }

  /**
   * Informação sobre carregamento do arquivo PDF
   * @param progressData Dados de progresso em bytes
   */
  onProgress(progressData: PDFProgressData) {
    this.progressData = progressData;
    this.isLoaded = false;
    this.error = null; // clear error
  }

  /* Obtém o valor arredondado de um número */
  getInt(value: number): number {
    return Math.round(value);
  }

  /**
   * Determina o tipo de arquivo pela extensão
   * @param doc Arquivo/Documento a ser conferido
   */
  getExtension(doc) {
    if (doc.endsWith('pdf')) {
      return 'pdf';
    } else {
      return 'imagem';
    }
  }

  /**
   * Altera o nível de zoom na visualização do PDF
   * @param amount Quantidade adicional de zoom
   */
  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  /**
   * Gira a visualização do PDF
   * @param angle Ângulo de rotação do documento
   */
  rotate(angle: number) {
    this.rotation += angle;
  }

  fecharDialogo(): void {
    this.dialogRef.close();
  }
}

// End of file
