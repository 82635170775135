import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  /* Método construtor */
  constructor(private el: ElementRef) {}

  /*     Após carregar a view, habilita o foco no elemento
   *   que possui esta diretiva declarada (appAutofocus)
   */
  ngAfterViewInit() {
    this.el.nativeElement.focus();
  }
}
