export const PRODUCTION = true;

export const SERVER_URL = 'https://app.snelloweb.com.br';

export const SERVER_API = 'https://snelloweb.com.br/api/v1';

export const SERVER_STORAGE = 'https://snelloprod.s3.sa-east-1.amazonaws.com';

export const SERVER_STORAGE_2 = 'https://snelloprod.s3.sa-east-1.amazonaws.com';

export const CLIENT_ID = '1';

export const CLIENT_SECRET = 'nk1vqsPqyZNsNxOn762awcBsRz6cipY2hl6QCgBu';

export const PUSHER_APP_ID = '907960';

export const PUSHER_APP_KEY = 'ed7ce0e0442ffa644643';

export const PUSHER_APP_SECRET = '57f3a0c516fa7ac1e7de';

// End of file
