import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Server } from '../server/server.service';

@Injectable({
  providedIn: 'root',
})
export class FormularioGuard implements CanActivate {
  /* Método Construtor */
  constructor(private server: Server) {}

  /* Solicita a API os dados do formulário */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const { token } = next.params;
    try {
      await this.server.get({ url: `obter-formulario/${token}` }).toPromise();
      return true;
    } catch (err) {
      console.error('token inválido,', err);
      return false;
    }
  }
}

// End of file
