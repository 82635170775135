import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Notificacao } from 'src/services/notificacoes/notificacao';
import { NotificacaoService } from 'src/services/notificacoes/notificacao.service';
import * as moment from 'moment';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss'],
  providers: [NotificacaoService],
})
export class NotificacaoComponent implements OnInit, OnDestroy {
  @Output() clickNotificacao = new EventEmitter();

  public notificacoes: Notificacao[] = [];
  private feedSubscription: Subscription;
  todasNotificacoes;
  loading = false;

  /* Método Construtor */
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificacaoService: NotificacaoService
  ) {
    this.feedSubscription = notificacaoService
      .getFeedItems()
      .subscribe((notificacao: Notificacao) => {
        this.notificacoes.push(notificacao);
      });
  }

  /* É executado ao iniciar */
  async ngOnInit() {
    const user = await this.authService.getUserInfo();
    if (user !== null) {
      await this.getNotificacoes();
    }
  }

  /* Busca todas as notificações */
  async getNotificacoes() {
    this.loading = true;
    try {
      const todasNotificacoes =
        await this.notificacaoService.obterNotificacoes();

      // Corrige a apresentação dos dados
      todasNotificacoes.find((element) => {
        if (element.data.visita) {
          element.updated_at = moment(
            element.created_at,
            'YYYYMMDD h:mm:ss'
          ).fromNow();
        } else {
          element.updated_at = moment(
            element.created_at,
            'YYYYMMDD h:mm:ss'
          ).fromNow();
        }
      });

      // Ordenas as mensagens, iniciando pelas não lidas
      this.todasNotificacoes = todasNotificacoes.sort((elem) => {
        if (elem.created_at > moment().format('LLLL')) {
          return -1;
        } else {
          return 1;
        }
      });
    } finally {
      this.loading = false;
    }
  }

  /* Abre a notificação de Agendamento de Visita */
  abreVisita(idVisita: number, notificacaoId: number) {
    this.router.navigateByUrl(`/visita/agendamento/${idVisita}`);
    this.notificacaoService.marcarNotificacao(notificacaoId);
    this.getNotificacoes();
    this.clickNotificacao.emit();
  }

  /* Abre a notificação de Documentação */
  abreDocumentacao(token, notificacaoId: number) {
    this.router.navigateByUrl(`/documentos/confirmar-documentos/${token}`);
    this.notificacaoService.marcarNotificacao(notificacaoId);
    this.getNotificacoes();
    this.clickNotificacao.emit();
  }

  ngOnDestroy() {
    this.feedSubscription.unsubscribe();
  }
}

// End of file
