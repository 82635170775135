import { ModalController } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { Selectable } from '../select/select.model';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss'],
})
export class SelectModalComponent {
  @Input()
  dataSet: Selectable[] = [];

  @Input()
  label = 'Selecione um item';

  /**
   * String de busca
   *
   */
  public query = '';

  /**
   * Item selecionado
   *
   */
  @Input()
  selected: Selectable = {};

  /**
   * Método construtor
   *
   */
  constructor(public modalCtrl: ModalController) {}

  /**
   * Esconde o modal
   *
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ value });
  }

  /**
   * Obtém os itens filtrados
   *
   */
  get cleanDataSet() {
    return this.dataSet.filter((item) => {
      return item.label.toLowerCase().indexOf(this.query.toLowerCase()) !== -1;
    });
  }

  /**
   * Seleciona um item
   *
   */
  selecionarItem(value) {
    const [item] = this.dataSet.filter((option) => option.value === value);
    this.dismiss(item);
  }

  /**
   * Verifica se um item esta selecionado
   *
   */
  isSelecionado(value) {
    return this.selected && this.selected.value === value;
  }
}

// End of file
