import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AlertaErroComponent } from './../../app/alertas/alerta-erro/alerta-erro.component';
import { AlertaSucessoComponent } from 'src/app/alertas/alerta-sucesso/alerta-sucesso.component';
import { AlertaConcluidoComponent } from 'src/app/alertas/alerta-concluido/alerta-concluido.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  /**
   * Titulo do alerta
   *
   */
  public titulo = 'Titulo';

  /**
   * Linhas do alerta
   *
   */
  public linhas = [];

  /**
   * Botao do alerta
   *
   */
  public botao = 'Ok, obrigado';

  /**
   * Método construtor
   *
   */
  constructor(public modalCtrl: ModalController) {}

  /**
   * Seta o titulo do alerta
   *
   */
  public setTitulo(titulo: string) {
    this.linhas = [];
    this.titulo = titulo;
    return this;
  }

  /**
   * Adiciona linha ao alerta
   *
   */
  public addLinha(linha: string) {
    this.linhas.push(linha);
    return this;
  }

  /**
   * Seta o texto do botao
   *
   */
  public setBotao(botao: string) {
    this.botao = botao;
    return this;
  }

  /**
   * Exibe o alerta de sucesso
   *
   */
  public async mostrarSucesso(callback: () => any = null) {
    const modal = await this.modalCtrl.create({
      component: AlertaSucessoComponent,
      componentProps: {
        titulo: this.titulo,
        content: this.linhas,
        successText: this.botao,
      },
    });
    await modal.present();
    await modal.onDidDismiss();
    if (callback) {
      callback();
    }
  }

  /**
   * Exibe o alerta de concluido
   *
   */
  public async mostrarConcluido(callback: () => any = null) {
    const modal = await this.modalCtrl.create({
      component: AlertaConcluidoComponent,
      componentProps: {
        titulo: this.titulo,
        content: this.linhas,
        concluidoText: this.botao,
      },
    });
    await modal.present();
    await modal.onDidDismiss();
    if (callback) {
      callback();
    }
  }

  /**
   * Mostra o alerta de erro
   *
   */
  public async mostrarErro(callback: () => any = null) {
    const modal = await this.modalCtrl.create({
      component: AlertaErroComponent,
      componentProps: {
        titulo: this.titulo,
        content: this.linhas,
        successText: this.botao,
      },
    });
    await modal.present();
    await modal.onDidDismiss();
    if (callback) {
      callback();
    }
  }
}

// End of file
