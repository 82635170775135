import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Events, MenuController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { User } from 'src/services/auth/user';
import { NotificacaoService } from 'src/services/notificacoes/notificacao.service';
import { AuthService } from './../services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  /* Observa se o usuário está logado */
  isLoggedIn$: Observable<boolean>;

  /* Informações do usuário a partir do local storage */
  user: User;

  notificacoes = false;
  notificacoesLidas;

  aberto = true;
  fechado = false;

  /* Método construtor */
  constructor(
    public menuCtrl: MenuController,
    private platform: Platform,
    private authService: AuthService,
    public events: Events,
    private router: Router,
    private snackBar: MatSnackBar,
    private notificacaoService: NotificacaoService
  ) {
    this.initializeApp();
  }

  /* Inicializa */
  async ngOnInit() {
    this.events.subscribe('get_user_info', async () => {
      this.user = await this.authService.getUserInfo();
      this.verificaNotificacoes();
    });
    this.isLoggedIn$ = this.authService.isLoggedIn;

    await this.buscaPerfil();
  }

  /* Busca os dados do usuário no storage */
  async buscaPerfil() {
    this.events.publish('get_user_info');
  }

  /* Carrega dados */
  initializeApp() {
    this.platform.ready().then(() => {
      this.menuCtrl.enable(true);
    });
  }

  fecharTodosMenu() {
    this.menuCtrl.close();
    this.notificacoes = false;
  }

  /* Exibe e oculta as notificações */
  mostraNotificacoes() {
    if (this.notificacoes === false) {
      this.fecharMenu();
      this.notificacoes = true;
    } else {
      this.notificacoes = false;
    }
  }

  /* Abre a listagem de visitas agendadas */
  abreVisitas() {
    this.menuCtrl.enable(true);
    this.router.navigate(['/visita/lista']);
  }

  /* Abre a listagem de contratos enviados */
  anexarContrato() {
    this.menuCtrl.enable(true);
    this.router.navigate(['/contratos-grid']);
    this.fecharMenu();
  }

  /* Abre a listagem de processos de locação */
  abrirDocumentos() {
    this.menuCtrl.enable(true);
    this.router.navigate(['/documentos/lista']);
    this.fecharMenu();
  }

  /* Abre a listagem de consultores */
  abreConsultores() {
    this.menuCtrl.enable(true);
    this.router.navigate(['/consultores/lista']);
    this.fecharMenu();
  }

  /* Abre a listagem de imóveis */
  abreImoveis() {
    this.menuCtrl.enable(true);
    this.router.navigate(['/imoveis/lista']);
    this.fecharMenu();
  }

  confereRota(rotas) {
    return rotas.indexOf(this.router.url) !== -1;
  }

  /* Faz o logout do usuário */
  async logMeOut() {
    await this.authService.logOut();
    await this.menuCtrl.close();
    this.menuCtrl.enable(false);
    this.router.navigateByUrl('/login');
  }

  /* Fecha a sidenav */
  fecharMenu() {
    this.menuCtrl.close();
  }

  clickNotificacao() {
    this.verificaNotificacoes();
    this.notificacoes = false;
  }

  minimizarMenu() {
    if (this.aberto) {
      this.aberto = false;
      this.fechado = true;
      document
        .querySelector('.split-pane-side')
        .setAttribute('style', 'min-width:70px;');
    } else {
      this.aberto = true;
      this.fechado = false;
      document
        .querySelector('.split-pane-side')
        .setAttribute('style', 'min-width:270px');
    }
  }

  confereMenu() {
    if (this.fechado) {
      return true;
    }
  }

  /* Marca todas as notificações como lidas */
  async marcarTodasLidas() {
    try {
      await this.notificacaoService.marcarTodasNotificacoes();

      this.notificacoes = false;

      this.snackBar.open(
        '✅ Todas as notificações foram marcadas como lidas',
        null,
        {
          duration: 3000,
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      await this.verificaNotificacoes();
    }
  }

  /* Verifica se existem notificações não lidas */
  async verificaNotificacoes() {
    // Verifica se tem notificações não lidas ainda
    const notificacoes = await this.notificacaoService.obterNotificacoes();
    const notificacoesLidas = [];
    notificacoes.forEach((element) => {
      notificacoesLidas.push(element.read_at);
    });

    // Observable para novas notificações
    this.notificacoesLidas = notificacoesLidas.includes(null);
    await this.notificacaoService.getFeedItems().subscribe(() => {
      this.notificacoesLidas = true;
    });
  }
}

// End of file
